<template>
  <div v-loading="loading" class="layout mt-4">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
      v-if="invoices.length > 0"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Paid Invoices</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_invoice" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <SearchIcon class="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                v-model="search"
                name="search_invoice"
                id="search_invoice"
                class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
                placeholder="Search"
              />
              <input
                type="text"
                name="search_invoice"
                id="search_invoice"
                class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
              />
            </div>
            <Menu>
              <div class="relative">
                <MenuButton
                  type="button"
                  class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                >
                  <SortAscendingIcon class="h-5 w-5 text-gray-400" />
                  <span class="ml-2">Sort</span>
                  <ChevronDownIcon
                    class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                  />
                </MenuButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-out"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="sort-menu"
                  >
                    <div class="py-1" role="none">
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Name</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date modified</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date created</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </div>
            </Menu>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col" v-if="invoices.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  ></th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Due
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Created
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="invoices.length > 0"
              >
                <tr v-for="invoice in invoices" :key="invoice.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      ${{ invoice.AmountDue }} USD
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      <span
                        class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                      >
                        Paid
                      </span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm font-medium text-gray-900">
                      {{ invoice.Customer.Name }}
                    </div>
                    <div class="max-w-sm truncate text-sm text-gray-500">
                      {{ invoice.Customer.Email }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{
                        new Date(invoice.DueDate).toLocaleDateString("en-us", {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{
                        new Date(invoice.Created).toLocaleDateString("en-us", {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty
        description="Oops, no paid invoices. Send some to your customers to get paid."
        :image-size="200"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";

import useInvoicesRepository from "@/composable/useInvoicesRepository";
import Pagination from "@/common/components/pagination";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import {
  SearchIcon,
  ChevronDownIcon,
  SortAscendingIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    Pagination,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    SearchIcon,
    SortAscendingIcon,
    ChevronDownIcon,
  },
  setup() {
    const { invoices, getPaidInvoices } = useInvoicesRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      totalResults: 0,
    });

    onMounted(() => {
      getPaidInvoices(pagination.page, pagination.pageSize);
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getPaidInvoices(pagination.page, pagination.pageSize);
    };

    return {
      invoices,
      pagination,
      onChangePage,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
</style>
